<template>
  <footer>
    <div class="row">
      <div class="footer-list-item">
        <ul class="menu menu--small inline-list">
          <li><a href="/">Home</a></li>
          <li><a href="/privacyinfo">Privacy Policy</a></li>
          <li><a href="/termsinfo">Terms of Use</a></li>
          <li>
            <a href="mailto:help@flamezonex.com" @click="clickSendEmail"
              >CONTACT US</a
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- <img
      src="@/assets/img/yellow-blob-2718f860fdfd80f471a0ee657cc43332.svg"
      class="yellow-home-blob"
      alt=""
      role="presentation"
    /> -->
  </footer>
</template>

<script>
export default {
  data() {
    return {
      yearTime: new Date().getFullYear()
    };
  },
  methods: {
    clickSendEmail() {
      //
    }
  }
};
</script>
