<template>
  <div id="page" class="hfeed site">
    <div id="appWrapper">
      <div>
        <!-- <button aria-hidden="true" id="hidden_skip_to_content" type="button">
          SKIP NAVIGATION
        </button> -->
        <Header />
        <section class="main-landing-container row">
          <div
            class="large-5 medium-0 columns hide-for-small-only hide-for-medium"
          >
            <img
              src="@/assets/img/11.png"
              alt=""
              role="presentation"
              style="
                position: relative;
                z-index: 1;
                max-height: 673.2px;
                min-height: 638px;
                opacity: 1;
                transition: opacity 0.35s ease 0s;
              "
            /><img
              src="@/assets/img/purple-ellipse-5597eaeb1881918fe9d1e4b9839d9d09.svg"
              alt=""
              role="presentation"
              style="
                bottom: 21px;
                right: -3px;
                position: absolute;
                min-height: 541px;
                opacity: 1;
                transition: opacity 0.35s ease 0s;
              "
            />
          </div>
          <div class="large-7 medium-12 columns">
            <div class="right-content-box">
              <h1>You've never played rollers like this before!</h1>
              <h2 class="sub-title">
                Try to find the best way to complete each level.
              </h2>
              <h2 class="sub-title">
                Some levels might look easy but let's see if you can actually
                get the 3 stars.
              </h2>

              <!-- <ul class="badges">
                <li>
                  <a
                    href="https://itunes.apple.com/us/app/zeemee/id1044878258?mt=8"
                    title="Download ZeeMee iOS"
                    ><img
                      src="/assets/pages-v2/apple-appstore-badge-aba9b69c781869202c63906d708c33ed.svg"
                      alt="Download from the Apple Store"
                  /></a>
                </li>
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.zeemee.zeemee_android&amp;hl=en"
                    title="Download ZeeMee Android"
                    ><img
                      src="/assets/pages-v2/google-play-badge-b6d13e6de117bfaf1d21d258d00c0eae.svg"
                      alt="Download from the Play Store"
                  /></a>
                </li>
              </ul> -->
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";

export default {
  name: "Home",
  components: {
    Header
  }
};
</script>

<style scoped></style>
