<template>
  <nav
    class="app-navbar light top-bar home"
    role="navigation"
    data-options="mobile_show_parent_link: false"
    :class="fixed"
  >
    <span style="flex: 0.5 1 0%"
      ><a
        href="/"
        class="top-bar home"
        style="font-size: 24px; font-weight: 600; padding-left: 32px"
      >
        Roll Up
      </a></span
    ><span
      class="top-bar-section"
      style="justify-content: flex-end; display: flex; flex: 1 1 0%"
      ><span class="right show-for-small has-dropdown"
        ><button
          aria-expanded="false"
          class="menu-bar"
          aria-label="Main navigation menu"
          aria-haspopup="true"
          type="button"
        >
          <i class="fa fa-bars fa-fw" style="pointer-events: none"></i>
        </button>
      </span>
    </span>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      fixed: ""
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", () => {
        const scrollTop = document.documentElement.scrollTop;
        this.fixed = scrollTop > 110 ? "border-bottom" : "";
      });
    });
  }
};
</script>
