import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import PrivacyPage from "../views/PrivacyTerms/PrivacyPage.vue";
import TermsPage from "../views/PrivacyTerms/TermsPage.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/support-me",
    name: "contact",
    component: Home
  },
  {
    path: "/privacyinfo",
    name: "privacy",
    component: PrivacyPage
  },
  {
    path: "/termsinfo",
    name: "terms",
    component: TermsPage
  },
  {
    path: "/read-terms",
    name: "terms",
    component: Home
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
